const Info = {
    about: "Hey",
    music: [
        'KJ9iiXySXtw'
    ],
    links: [
        {
            name: "GitHub",
            url: "https://github.com/EngineerMark",
            color: "#171515"
        },
        {
            name: "Twitter",
            url: "https://twitter.com/id2amayakase",
            color: "#1DA1F2"
        },
        {
            name: "osu!",
            url: "https://osu.ppy.sh/users/10153735",
            color: "#FF66AA"
        },
        {
            name: "Steam",
            url: "https://steamcommunity.com/id/amayakase/",
            color: "#1b2838"
        },
        {
            name: "Youtube",
            url: "https://www.youtube.com/channel/UCDx9uyBeFr2cxIHmwEisPyw",
            color: "#FF0000"
        },
        {
            name: "AniList",
            url: "https://anilist.co/user/amayakase/",
            color: "#2E51A2"
        },
        {
            name: "Discord",
            url: "https://discord.com/users/232878037337636865",
            color: "#7289DA"
        }
    ],
    projects: [
        {
            name: "osu! scores inspector",
            url: "https://score.kirino.sh/",
            color: "#FF66AA"
        },
        {
            name: "open chatter",
            url: "https://github.com/EngineerMark/open-chatter",
            color: "#FF66AA"
        }
    ],
    systems: [
        {
            name: "Gaming PC",
            cpu: "AMD Ryzen 5 5600G",
            gpu: "NVIDIA GeForce RTX 3060 12G",
            ram: "32GB DDR4 2666MHz (2x16GB)",
            storage: [
                {
                    type: "NVME",
                    size: "1TB",
                },
                {
                    type: "SSD",
                    size: "1TB",
                },
                {
                    type: "HDD",
                    size: "4TB",
                }
            ]
        },
        {
            name: "Server",
            cpu: "AMD Ryzen 5 2600",
            gpu: "NVIDIA GeForce RTX 4070 12G",
            ram: "16GB DDR4 2666MHz (2x8GB)",
            storage: [
                {
                    type: "NVME",
                    size: "256GB",
                },
                {
                    type: "HDD",
                    size: "4TB",
                },
                {
                    type: "HDD",
                    size: "4TB",
                },
                {
                    type: "HDD",
                    size: "4TB",
                }
            ]
        }
    ]
}

export default Info;